import { useAuthentication } from '@whitelabel-webapp/authentication/shared/authentication-store';
import { useMerchant } from '@whitelabel-webapp/merchant/shared/merchant-store';
import dynamic from 'next/dynamic';
import { useEffect, useMemo, useState } from 'react';

import { AuthenticationWithContextIdProps } from '../AuthenticationWithContextId/types';
import { AuthenticationProps as AuthenticationWithEmailProps } from "../AuthenticationWithEmail/types";

const AuthenticationWithContextId = dynamic<AuthenticationWithContextIdProps>(
  () =>
    import("../AuthenticationWithContextId").then(
      ({ AuthenticationWithContextId }) => AuthenticationWithContextId,
    ),
);
const AuthenticationWithEmail = dynamic<AuthenticationWithEmailProps>(() =>
  import("../AuthenticationWithEmail").then(
    ({ AuthenticationWithEmail }) => AuthenticationWithEmail,
  ),
);

export type IfoodAuthenticationSteps =
  | "HIDDEN"
  | "AUTHENTICATION_WITH_EMAIL"
  | "AUTHENTICATION_WITH_CONTEXT_ID";

export const IfoodAuthentication: React.VFC = () => {
  const { merchant } = useMerchant();
  const { ifoodAuthenticationConfig, closeIfoodAuthentication } =
    useAuthentication();

  const [step, setStep] = useState<IfoodAuthenticationSteps>("HIDDEN");

  const stepByMethod = useMemo(
    () => ({
      EMAIL: () => setStep("AUTHENTICATION_WITH_EMAIL"),
      CONTEXT_ID: () => setStep("AUTHENTICATION_WITH_CONTEXT_ID"),
    }),
    [],
  );

  useEffect(() => {
    const { method } = ifoodAuthenticationConfig;

    stepByMethod[method]();
  }, [
    ifoodAuthenticationConfig,
    ifoodAuthenticationConfig.method,
    stepByMethod,
  ]);

  function handleClose() {
    closeIfoodAuthentication();
  }

  const { isOpen, options } = ifoodAuthenticationConfig;

  if (!isOpen) return null;

  return (
    <>
      {step === "AUTHENTICATION_WITH_EMAIL" && (
        <AuthenticationWithEmail merchant={merchant} onClose={handleClose} />
      )}
      {step === "AUTHENTICATION_WITH_CONTEXT_ID" && (
        <AuthenticationWithContextId
          merchant={merchant}
          contextId={options?.additionalInfo?.["contextId"] || ""}
          onClose={handleClose}
        />
      )}
    </>
  );
};
